import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    customerDropInvoice() {
      return new Promise((resolve, reject) => {
        axios
          .get('customerDropInvoice')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('fetchInvoices', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchArchiveInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('fetchArchiveInvoices', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },



    
    lastWeek(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('lastWeekEvents', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`showInvoice/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

    saveInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .post('saveInvoice', invoiceData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`updateInvoice`, invoiceData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    approveInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`approveInvoice`, invoiceData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    archiveInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`archiveInvoice`, invoiceData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    printInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('printInvoice', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    sendInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('sendInvoice', { id: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    historyData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('historyData', { id: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getInvoiceData(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`getInvoiceData`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getNonInvoicedData(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`getNonInvoicedData`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveBilling(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`saveBilling`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    getApprovalBilling(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`getApprovalBilling`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    approveBilling(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`approveBilling`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFinalBilling(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`getFinalBilling`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getFinalizedBilling(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`getFinalizedBilling`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    finalizeBilling(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`finalizeBilling`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    exportExcel(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          //.get("user", { params: queryParams })
          .post('exportExcel',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    
    updateChargeString(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`updateChargeString`, invoiceData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    getEmployeeHours(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`getEmployeeHours`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    exportEmployeeHours(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          //.get("user", { params: queryParams })
          .post('exportEmployeeHours',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getMaterialsUsed(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`getMaterialsUsed`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    exportMaterials(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          //.get("user", { params: queryParams })
          .post('exportMaterials',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    
    
    
    

    
  },
};
